<template>
  <div id="login" style="min-height: calc(100vh - 20px)" tag="section">
    <b-row class="justify-content-center no-gutters">
      <b-col lg="12" class="d-flex align-items-center">
        <b-card no-body class="w-100">
          <b-row class="no-gutters" style="min-height: 100vh">
            <b-col
              lg="6"
              class="bg-primary d-md-flex align-items-center justify-content-center"
            >
              <div class="d-flex align-items-center">
                <div class="p-5">
                  <b-row class="justify-content-center">
                    <b-col cols="12" lg="9" xl="9">
                      <div>
                        <h2 class="display-5 text-white fw-medium"></h2>
                        <p class="mt-4 text-white op-5 font-weight-normal">
                          Wrappixel helps developers to build organized and
                          well-coded admin dashboards full of beautiful and
                          feature rich modules.
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
            <b-col
              lg="6"
              class="d-md-flex align-items-center justify-content-center"
            >
              <div class="d-flex align-items-center w-100">
                <div class="p-5 w-100">
                  <b-row class="justify-content-center">
                    <b-col cols="12" lg="9" xl="6">
                      <img src="@/assets/images/logo.png" width="300" />
                      <h2 class="font-weight-bold mt-4">
                        {{ $t('GERAL.REDEFINIR_SENHA') }}
                      </h2>

                      <input-text
                        ref="senha"
                        v-model="form.senha"
                        noLabel
                        type="password"
                        :label="$t('GERAL.SENHA')"
                        :placeholder="$t('GERAL.SENHA')"
                        required
                      />
                      <input-text
                        ref="novaSenha"
                        v-model="form.novaSenha"
                        noLabel
                        type="password"
                        :label="$t('GERAL.CONFIRMACAO_SENHA')"
                        :placeholder="$t('GERAL.CONFIRMACAO_SENHA')"
                        :validate="validarSenha"
                        required
                      />

                      <b-button
                        variant="primary"
                        size="lg"
                        block
                        class="mt-4"
                        @click="resetarSenha"
                        >{{ $t('GERAL.ALTERAR_SENHA') }}</b-button
                      >

                      <div class="ml-auto">
                        <a
                          @click="voltarLogin"
                          href="javascript:void(0)"
                          class="link"
                          >{{ $t('GERAL.VOLTAR_LOGIN') }}</a
                        >
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import InputText from '@/components/inputs/InputText';
import UsuarioService from '@/common/services/usuario/usuario.service';
import Swal from 'sweetalert2';

export default {
  name: 'Login',
  components: {
    InputText,
  },
  data() {
    return {
      form: {
        usuarioId: this.$route.query.usuarioId,
        token: this.$route.query.token,
        novaSenha: null,
      },
    };
  },
  computed: {
    validarSenha() {
      if (!this.comparaSenha()) return false;
      return true;
    },
  },
  methods: {
    validarFormulario() {
      let arValidation = [];
      arValidation.push(this.$refs.novaSenha.valid());
      arValidation.push(this.$refs.senha.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    resetarSenha() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      const form = this.form;
      UsuarioService.resetarSenha(form)
        .then(() => {
          Swal.fire({
            title: this.$t('GERAL.SUCESSO'),
            text: this.$t('GERAL.SENHA_ALTERADA'),
            icon: 'success',
            confirmButtonText: this.$t('GERAL.OK'),
          });
        })
        .catch((err) => {
          Swal.fire({
            title: this.$t('GERAL.ERRO'),
            text: err.response.data.errors,
            icon: 'error',
          });
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    comparaSenha() {
      if (this.form.senha !== this.form.novaSenha) {
        return false;
      }
      return true;
    },
    voltarLogin() {
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>
